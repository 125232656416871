<template>
  <div class="p-4">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal"
    />
    <h1
      v-if="isEdit"
      class="text-xl"
    >
      Opdater sag
    </h1>
    <h1
      v-else
      class="text-xl"
    >
      Opret ny sag
    </h1>
    <div>
      <div class="flex mt-4 mb-4">
        <steps
          :steps="steps"
          :active-step="stepsMap[step]"
          :update-step="updateStep"
        />
      </div>
    </div>
    <div class="md:px-4 md:py-2">
      <!-- STEP 1 -->
      <div v-show="step == 'step-1'">
        <div class="py-2">
          <label
            for="law_options"
            class="flex font-semibold"
          >
            Vælg relevant lovgivning
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
            <tooltip field_name="law_options" />
          </label>
          <multiselect
            v-model="process.law_set"
            :options="lawSets"
            :multiple="false"
            :searchable="false"
            :allow-empty="false"
            :close-on-select="true"
            :loading="!lawSets || lawSets.length==0"
            label="display_name"
            track-by="id"
            placeholder="Vælg relevant lovgivning"
            :option-height="150"
            select-label
            deselect-label
            selected-label
            data-testid="processLawSelector"
            @update:model-value="updateEndDate"
          />
        </div>
        <!-- Request dates -->
        <div class="py-2">
          <div class="font-semibold flex">
            Anmodning og frist dato <icon-asterisk title="Skal udfyldes" class="w-4 text-red-500 mx-1" />
            <tooltip 
              :field_name="process.law_set.name"
              size="is-large"
            />
          </div>

          <div class="flex my-2 w-full items-center">
            <label
              for="case_received_date"
              class="w-1/2 block text-sm font-medium"
            >Anmodet dato
            </label>

            <input
              id="case_received_date"
              v-model="process.request_received_date"
              type="date"
              :max="today"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
          </div>
          <div class="flex my-2 w-full items-center">
            <label
              for="case_deadline_date"
              class="w-1/2 block text-sm font-medium"
            >Frist for besvarelse
            </label>
            <input
              id="case_deadline_date"
              v-model="process.request_deadline_date"
              type="date"
              :min="process.request_received_date"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
          </div>
          <div
            v-if="isEdit && showRequestDeadlineReason"
            class="flex my-2 w-full items-center"
          >
            <label
              for="request_deadline_reason"
              class="w-1/2 block text-sm font-medium"
            >Ændring af frist for besvarelse
            </label>
            <textarea
              id="request_deadline_reason"
              v-model="process.request_deadline_reason"
              placeholder="Angiv begrundelse for ændring af frist for besvarelse"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
        </div>
        <!-- Requester -->
        <div
          v-if="
            process.law_set.display_name === 'Forvaltningsloven' ||
              process.law_set.display_name === 'Sundhedsloven'
          "
          class="py-2"
        >
          <div class="font-semibold flex">
            Hvem er anmoder?
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
            <tooltip field_name="who_is_requesting" />
          </div>

          <div class="flex my-2 w-full items-center">
            <label
              for="case_requester_type"
              class="w-1/2 block text-sm font-medium"
            >Type
            </label>
            <multiselect
              v-model="process.requester_type"
              :options="requesterTypeOptions"
              :multiple="false"
              :allow-empty="false"
              :searchable="false"
              :close-on-select="true"
              placeholder="Vælg type af anmoder"
              :option-height="150"
              select-label
              deselect-label
              selected-label
              data-testid="requesterTypeSelector"
            />
          </div>
          <!-- <div
              class="flex my-2 w-full items-center"
              v-show="process.requester_type === 'Anden'"
            >
              <label
                for="case_name"
                class="w-1/2 block text-sm font-medium"
                >Navn på myndighed
                <tooltip field_name="law_options" />
              </label>
              <input
                v-model="process.requester_name"
                type="text"
                placeholder="Navn på anmodende myndighed"
                id="case_name"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div> -->
          <div
            v-show="process.requester_type !== 'Borger selv' && process.requester_type !== 'Virksomhed'"
            class="flex my-2 w-full items-center"
          >
            <label
              for="case_name"
              class="w-1/2 block text-sm font-medium"
            >
              Navn på anmoder
            </label>
            <input
              id="case_name"
              v-model="process.requester_name"
              type="text"
              placeholder="Navn på anmoder"
              autocomplete="off"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
          </div>
          <div
            v-show="enableCPRInput && process.requester_type === 'Forældremyndighedsindehaver'"
          >
            <form class="flex my-2 w-full items-center">
              <label
                for="requester_cpr"
                class="w-1/2 block text-sm font-medium"
              >Anmoders CPR-nr.</label>
              <input
                id="requester_cpr"
                v-model="process.requester_cpr"
                type="text"
                placeholder="Anmoders CPR-nr."
                autocomplete="off"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                @input="v$.requester_cpr.$touch()"
              >
            </form>
            <div
              v-if="
                enableCPRInput &&
                v$.requester_cpr.$dirty &&
                  !v$.requester_cpr.required
              "
              class="text-red-600 text-sm"
            >
              CPR skal udfyldes
            </div>
            <div
              v-if="enableCPRInput && !v$.requester_cpr.regex"
              class="text-red-600 text-sm"
            >
              Indtast venligst et korrekt CPR
            </div>
          </div>
        </div>
        <!-- documentation-->
        <div
          v-if="!isEdit"
          class="py-2"
        >
          <div class="font-semibold">
            Dokumentation
            <tooltip field_name="documentation" />
          </div>
          <div class="flex my-2 w-full items-center">
            <label class="w-1/2 block text-sm font-medium">Anmodning </label>
            <redaction-documentation-upload
              documentation-type="original_request"
              upload-text="Klik her for at tilføje anmodning(er)"
            />
          </div>
          
          <div
            v-if="
              (process.requester_type === 'Bemyndiget tredjepart' ||
                process.requester_type === 'Anden') &&
                process.law_set.display_name !== 'Offentlighedsloven'
            "
            class="flex my-2 w-full items-center"
          >
            <label class="w-1/2 block text-sm font-medium">Fuldmagt</label>
            <redaction-documentation-upload
              documentation-type="power_of_attorney"
              upload-text="Klik her for at tilføje fuldmagt(er)"
            />
          </div>
        </div>
        <!-- next step -->
        <div class="py-3 mt-2 mb-4">
          <standard-button
            class="w-full"
            button-text="Næste"
            button-type="standard-disableable"
            :button-disabled="!step1ready"
            @click="goToStep2()"
          />
        </div>
      </div>
      <!-- STEP 2-->
      <div v-show="step == 'step-2'">
        <!-- Who/what is request about? -->
        <div class="py-2">
          <div
            v-if="
              (process.law_set.display_name === 'Forvaltningsloven' ||
                process.law_set.display_name === 'Sundhedsloven') &&
                process.requester_type === 'Borger selv'
            "
            class="flex font-semibold"
          >
            Hvem har søgt aktindsigt?
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
            <tooltip field_name="who_requested_aktinsigt" />
          </div>
          <div
            v-if="process.requester_type === 'Forældremyndighedsindehaver'"
            class="flex font-semibold mb-2 space-x-1"
          >
            <div class="self-center flex">
              Barnets navn<icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
            </div>
            <!-- <standard-button
              v-if="!isEdit"
              button-size="small"
              button-text="Tilføj barn"
              button-icon="plus"
              button-type="standard"
              @click="addAnotherSubject()"
            /> -->
          </div>
          <div
            v-if="
              (process.law_set.display_name === 'Forvaltningsloven' ||
                process.law_set.display_name === 'Sundhedsloven') &&
                (process.requester_type === 'Bemyndiget tredjepart' ||
                  process.requester_type === 'Anden')
            "
            class="flex font-semibold"
          >
            Hvem søges der aktindsigt på baggrund af?
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
          </div>
          <div
            v-if="
              process.law_set.display_name === 'Offentlighedsloven' ||
                process.law_set.display_name === 'Miljøoplysningsloven' ||
                process.law_set.display_name === 'Egenacces jf. Offentlighedsloven' ||
                process.law_set.display_name === 'Indsigtssag'
            "
            class="flex font-semibold"
          >
            Hvad søges der indsigt i?
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
          </div>
          <div
            v-if="
              process.law_set.display_name === 'Offentlighedsloven' ||
                process.law_set.display_name === 'Miljøoplysningsloven' ||
                process.law_set.display_name === 'Egenacces jf. Offentlighedsloven' ||
                process.law_set.display_name === 'Indsigtssag'
            "
          >
            <div
              v-for="(requestSubject, requestSubjectIndex) in process
                .request_subjects"
              :key="requestSubject.id"
              class="flex my-2 w-full items-center"
            >
              <input
                :id="'request_subject_name-' + requestSubjectIndex"
                v-model.trim="requestSubject.name"
                type="text"
                placeholder="Angiv hvad der søges aktindsigt i?"
                autocomplete="off"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
            </div>
            <div class="flex font-semibold">
              Hvem er anmoder?
            </div>
            <div class="flex w-full">
              <label
                for="requester_name"
                class="block text-sm font-medium"
              />
              <input
                id="requester_name"
                v-model="process.requester_name"
                type="text"
                placeholder="Navn på anmoder"
                autocomplete="off"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
            </div>
          </div>
          <!-- Forældremyndighedsindehaver -->
          <div v-if="process.requester_type === 'Forældremyndighedsindehaver'">
            <div
              v-for="(requestSubject, requestSubjectIndex) in process
                .request_subjects"
              :key="requestSubject.id"
            >
              <div class="flex">
                <!-- <div class="w-12 flex flex-col justify-between">
                  <div class="text-xl">
                    {{ Number(requestSubjectIndex) + 1 }}
                  </div>
                  <div> REMOVE/TILFØJ BARN
                    <standard-button
                      v-show="Number(requestSubjectIndex) > 0"
                      button-title="Fjern"
                      button-type="delete"
                      button-icon="remove"
                      @click="
                        deleteRequestSubject(
                          requestSubject.id,
                          requestSubjectIndex
                        )
                      "
                    />
                  </div>
                </div> -->
                <div class="pl-4 w-full">
                  <div class="flex my-2 items-center">
                    <label
                      :for="'request_person_name-' + requestSubjectIndex"
                      class="w-1/2 block text-sm font-medium"
                    >
                      Barnets fulde navn
                    </label>
                    <input
                      :id="'request_person_name-' + requestSubjectIndex"
                      v-model.trim="requestSubject.name"
                      type="text"
                      placeholder="Barnets fulde navn"
                      autocomplete="off"
                      :data-testid="`requestPersonNameField${requestSubjectIndex}`"
                      class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                  </div>
                  <form v-if="enableCPRInput" class="flex my-2 w-full items-center">
                    <label
                      v-if="
                        process.requester_type === 'Forældremyndighedsindehaver'
                      "
                      :for="'request_person_cpr-' + requestSubjectIndex"
                      class="w-1/2 block text-sm font-medium"
                    >
                      Barnets CPR-nr.
                    </label>
                    <input
                      :id="'request_person_cpr-' + requestSubjectIndex"
                      v-model.trim="requestSubject.cpr"
                      type="text"
                      placeholder="Barnets CPR-nr."
                      autocomplete="off"
                      :data-testid="`requestPersonCPRField${requestSubjectIndex}`"
                      class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      @blur="touchField(v$.request_subjects.$each.$response.$data[requestSubjectIndex].cpr)"
                    >
                  </form>
                  <div
                    v-if="
                      enableCPRInput &&
                        v$.request_subjects.$each.$response.$data[requestSubjectIndex]?.cpr?.$dirty && 
                        !v$.request_subjects.$each.$response.$data[requestSubjectIndex].cpr.required
                    "
                    class="text-red-600 text-sm ml-9"
                  >
                    CPR skal udfyldes
                  </div>
                  <div
                    v-if="enableCPRInput && !v$.request_subjects.$each.$response.$data[requestSubjectIndex].cpr.regex"
                    class="text-red-600 text-sm ml-9"
                  >
                    {{ v$.request_subjects.$each.$response.$errors[requestSubjectIndex]?.cpr[0]?.$message || "Indtast venligst et korrekt CPR" }}
                  </div>
                </div>
              </div>
              <hr class="my-3">
            </div>
          </div>
          <!-- non Forældremyndighedsindehaver -->
          <!-- Virksomhed -->
          <div v-else-if="process.requester_type === 'Virksomhed'">
            <div
              v-if="
                (process.law_set.display_name === 'Forvaltningsloven' ||
                  process.law_set.display_name === 'Sundhedsloven') &&
                  process.requester_type === 'Borger selv'
              "
              class="flex font-semibold"
            >
              Hvem har søgt aktindsigt?
              <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
              <tooltip field_name="who_requested_aktinsigt" />
            </div>
            <div
              v-for="(requestSubject, requestSubjectIndex) in process
                .request_subjects"
              :key="requestSubject.id"
            >
              <div class="flex divide-x divide-gray-300">
                <div class="pl-4 w-full">
                  <div class="flex my-2 items-center">
                    <label
                      :for="'request_person_name-' + requestSubjectIndex"
                      class="w-1/2 block text-sm font-medium"
                    >
                      Virksomhedsnavn
                    </label>
                    <input
                      :id="'request_person_name-' + requestSubjectIndex"
                      v-model.trim="requestSubject.name"
                      type="text"
                      placeholder="Navn på virksomhed"
                      autocomplete="off"
                      :data-testid="`requestPersonNameField${requestSubjectIndex}`"
                      class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                  </div>
                </div>
              </div>
              <hr class="my-3">
            </div>
          </div>
          <!-- non Virksomhed -->
          <div v-else>
            <div
              v-for="(requestSubject, requestSubjectIndex) in process.request_subjects"
              :key="requestSubject.id"
            >
              <div
                v-if="
                  process.law_set.display_name === 'Forvaltningsloven' ||
                    process.law_set.display_name === 'Sundhedsloven'
                "
                class="flex my-2 w-full items-center"
              >
                <label
                  :for="'request_person_name-' + requestSubjectIndex"
                  class="w-1/2 block text-sm font-medium"
                >
                  Fulde navn
                </label>
                <input
                  :id="'request_person_name-' + requestSubjectIndex"
                  v-model.trim="requestSubject.name"
                  type="text"
                  placeholder="Fulde navn"
                  autocomplete="off"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
              </div>
              <form
                v-if="
                  enableCPRInput &&
                    (process.law_set.display_name === 'Forvaltningsloven')
                "
                class="flex my-2 w-full items-center"
              >
                <label
                  :for="'request_person_cpr-' + requestSubjectIndex"
                  class="w-1/2 block text-sm font-medium"
                >
                  Angiv CPR-Nr.
                </label>
                <input
                  :id="'request_person_cpr-' + requestSubjectIndex"
                  v-model.trim="requestSubject.cpr"
                  type="text"
                  placeholder="CPR"
                  autocomplete="off"
                  class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  @blur="touchField(v$.request_subjects.$each.$response.$data[requestSubjectIndex].cpr)"
                >
              </form>
              <div
                v-if="enableCPRInput && v$.request_subjects.$each.$response.$data[requestSubjectIndex].cpr.$dirty && !v$.request_subjects.$each.$response.$data[requestSubjectIndex].cpr.required"
                class="text-red-600 text-sm"
              >
                CPR skal udfyldes
              </div>
              <div
                v-if="enableCPRInput && !v$.request_subjects.$each.$response.$data[requestSubjectIndex].cpr.regex"
                class="text-red-600 text-sm"
              >
                {{ v$.request_subjects.$each.$response.$errors[requestSubjectIndex]?.cpr[0]?.$message || "Indtast venligst et korrekt CPR" }}
              </div>
            </div>
          </div>
          <input
            id="request_reference_number"
            v-model="process.reference_number"
            hidden
            type="hidden"
            autocomplete="off"
            placeholder="Angiv evt. referencenumber"
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
        </div>
        <!-- Org info -->
        <!-- <div class="py-2">
            <div class="font-semibold">Organisation</div>
            <div class="flex my-2 w-full items-center">
              <label
                for="request_admin_unit"
                class="w-1/4 block text-sm font-medium"
                >Vælg forvaltning</label
              >
              <multiselect
                v-model="process.request_admin_unit"
                id="request_admin_unit"
                :options="adminUnitOptions"
                :multiple="false"
                :searchable="false"
                :close-on-select="true"
                placeholder="Vælg forvaltning"
                :option-height="150"
                selectLabel
                deselectLabel
                selectedLabel
              >
              </multiselect>
            </div>
            <div class="flex my-2 w-full items-center">
              <label
                for="request_department"
                class="w-1/4 block text-sm font-medium"
                >Angiv afdeling</label
              >
              <input
                v-model="process.request_department"
                type="text"
                placeholder="Angiv områdenøgle. Fx. BUS01"
                id="request_department"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div> -->
        <!-- Request date filters -->
        <div class="py-2">
          <div class="flex font-semibold">
            Tidsperiode for indsigt
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
            <tooltip field_name="insight_period" />
          </div>
          <div class="flex my-2 w-full items-center">
            <label
              for="radio_date_full"
              class="cursor-pointer"
            >
              <input
                id="radio_date_full"
                v-model="process.request_date_full"
                type="radio"
                :value="true"
              >
              <span class="mx-2">Ingen tidsafgrænsning</span>
            </label>
            <label
              class="mx-4 cursor-pointer"
              for="radio_case_time_limit"
            >
              <input
                id="radio_case_time_limit"
                v-model="process.request_date_full"
                type="radio"
                :value="false"
              >
              <span class="mx-2">Tidsafgrænset</span>
            </label>
          </div>
          <div
            v-show="!process.request_date_full"
            class="flex my-2 w-full items-center"
          >
            <label
              for="case_from_date"
              class="w-1/2 block text-sm font-medium"
            >Startdato</label>
            <input
              id="case_from_date"
              v-model="process.request_from_date"
              type="date"
              :max="today"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
          </div>
          <div
            v-show="!process.request_date_full"
            class="flex my-2 w-full items-center"
          >
            <label
              for="case_to_date"
              class="w-1/2 block text-sm font-medium"
            >Slutdato</label>
            <input
              id="case_to_date"
              v-model="process.request_to_date"
              type="date"
              :min="request_to_date_min"
              :max="today"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
          </div>
        </div>
        <!-- Request other delimitations  -->
        <div class="py-2">
          <div class="flex font-semibold">
            Anmodning kort beskrevet
            <tooltip field_name="further_delimitation" />
          </div>
          <div class="flex my-2 w-full items-center">
            <input
              id="case_other_delimitation"
              v-model="process.request_other_delimitations"
              type="text"
              autocomplete="off"
              placeholder="evt. hvad er der anmodet om?"
              class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
          </div>
        </div>
        <div class="py-3 mt-2 mb-4 flex space-x-2">
          <standard-button
            class="w-full"
            button-text="Tilbage"
            button-type="standard"
            @click="goToStep1()"
          />
          <standard-button
            class="w-full"
            button-text="Næste"
            button-type="standard-disableable"
            :button-disabled="!step2ready"
            @click="goToStep3()"
          />
        </div>
      </div>
      <!-- STEP 3 -->
      <div 
        v-show="step == 'step-3'" 
        class="step3"
      >
        <!-- title -->
        <div
          v-if="isEdit"
          class="py-2"
        >
          <div class="flex font-semibold">
            Sagsnavn
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
          </div>
          <input
            id="process_title"
            v-model="process.display_name"
            type="text"
            autocomplete="off"
            placeholder="Indtast venligst et sagsnavn."
            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
        </div>
        <div class="py-2">
          <div class="flex font-semibold">
            <standard-button
              v-if="!isEdit"
              button-size="small"
              button-text="Hent dokumenter"
              button-icon="pdf"
              button-type="standard"
              @click="openImportExtDocModal()"
            />
            <tooltip 
              v-if="!isEdit"
              field_name="import_integration"
            />
          </div>
          <div class="text-blue-600">
            {{ acadreDocumentsCount === 0 ? '' : 
              (acadreDocumentsCount === 1 ? '1 valgt dokument' : 
                acadreDocumentsCount + ' valgte dokumenter') }}
          </div>
        </div>
        <!-- How should docs be transfered -->
        <div
          v-if="!isEdit && showRPA()"
          class="py-2"
        >
          <div class="flex font-semibold mb-2">
            Hvordan skal dokumenter overføres?
            <icon-asterisk class="w-4 h-4 text-red-500 mx-1" />
            <tooltip field_name="document_transfer_method" />
          </div>
          <div
            v-if="disableRPA()"
            class="inline-flex mr-2"
          >
            <label
              for="checkbox_rpa"
              class="bg-purple-500 p-2 rounded text-white flex"
              @click="toastIfDisabled()"
            >
              <input
                id="checkbox_rpa"
                v-model="isRPASelected"
                type="checkbox"
                class="form-checkbox h-5 w-5"
                :disabled="!showRPA()"
                @change="updateProcessType"
              >
              <redaction-icon-robot class="ml-2 mr-2 w-6 h-6" />
              <span class="mx-2">
                Lad robotten hente sagens dokumenter
              </span>
            </label>
          </div>
          <div v-if="showRPA() && process.request_process_type === 'rpa'">
            <div class="py-2">
              <label
                for="document_types_selected"
                class="flex font-semibold"
              >
                Vælg de typer af dokumenter som RPA'en skal søge efter
              </label>
              <multiselect
                id="document_types_selected"
                v-model="process.document_types"
                :options="rpaDocumentTypes"
                track-by="id"
                label="display_name"
                :multiple="true"
                :searchable="false"
                :close-on-select="false"
                open-direction="top"
                placeholder="Klik her og vælg i listen af dokumenttyper."
                :option-height="150"
                select-label
                deselect-label
                selected-label
              />
            </div>
          </div>
        </div>
        <div class="py-3 mb-4 flex space-x-2">
          <standard-button
            class="w-full"
            button-text="Tilbage"
            button-type="standard"
            :disabled="clicked.includes('nySagClicked')"
            @click="goToStep2()"
          />
          <standard-button
            class="w-full"
            :button-text="isEdit ? 'Opdater sag' : 'Opret ny sag'"
            button-type="standard-disableable"
            :button-disabled="!step3ready"
            :disabled="clicked.includes('nySagClicked')"
            @click="createCase()"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, useStore } from "vuex";
import { reactive, onMounted, ref, computed, getCurrentInstance } from "vue";
import { v4 as uuidv4 } from "uuid";
import Multiselect from "vue-multiselect";
import buttonExit from "@/components/controls/buttonExit.vue";
import iconRobot from "@/components/icons/robot.vue";
import iconPdf from "@/components/icons/pdf.vue";
import iconShare from "@/components/icons/share.vue";
import ImportExternalDocumentsForm from "@/components/forms/ImportExternalDocumentsForm.vue";
// import ChevronRight from "@/components/icons/chevronRight.vue";
// import { validationMixin } from "vuelidate";
import { useVuelidate, VuelidateMixin } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import IconAsterisk from "@/components/icons/asterisk.vue";
import DocumentationUpload from "@/components/DocumentationUpload.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import Steps from './Steps.vue';
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/da';
import holidays_DA from '../../assets/json/holidaysDA_2024-2030.json';
import Tooltip from "./tooltip.vue";
import FEATURE_FLAGS from '@/constants/featureflags';
import mitt from 'mitt';

const emitter = mitt();
dayjs.extend(dayjsBusinessDays);
dayjs.extend(updateLocale);
dayjs.locale('da');

const holidays = holidays_DA.map(holiday => holiday.date);

dayjs.updateLocale('da', {
  holidays: holidays,
  holidayFormat: 'YYYY-MM-DD',
  workingWeekdays: [1, 2, 3, 4, 5],  // Monday to Friday are working days
});

import _ from "lodash";

export default {
  name: "EditCaseForm",
  // disableSubmit: false,
  // isActive: false,
  // mixins: [VuelidateMixin],
  components: {
    Multiselect,
    // ChevronRight,
    IconAsterisk,
    "redaction-button-exit": buttonExit,
    "redaction-icon-robot": iconRobot,
    // "redaction-icon-pdf": iconPdf,
    "redaction-documentation-upload": DocumentationUpload,
    "steps": Steps,
    StandardButton,
    Tooltip,
  },
  props: {
    processId: String,
    // disableSubmit: Boolean,
    // isActive: Boolean,
  },
  setup(props) {
    const store = useStore();
    const instance = getCurrentInstance();
    const proxy = instance ? instance.proxy : null;
    // Brug computed properties til at få adgang til Vuex state
    const documentTypes = computed(() => store.state.documentTypes);
    const openProcess = computed(() => store.state.openProcess);
    const processes = computed(() => store.state.processes);
    const user = computed(() => store.state.user);
    const documentationToUpload = computed(() => store.state.documentationToUpload);
    const defaultProcessStatus = computed(() => store.state.defaultProcessStatus);
    const openDocumentations = computed(() => store.state.openDocumentations);
    const tooltips = computed(() => store.state.tooltips);
    const lawSets = computed(() => store.state.lawSets);
    const lawsLoaded = computed(() => store.state.lawsLoaded);
    const defaultLawSet = computed(() => store.state.defaultLawSet);
    const acadreDocumentsCount = computed(() => store.state.acadre.acadreDocumentsCount);
    const rpaDocumentTypes = computed(() => {
      return documentTypes.value.filter(dt => dt.enable_for_rpa_transfer);
    });
    const enableCPRInput = ref(false);
    // data
    const process = reactive({
      request_person_cpr: "",
      requester_cpr: "",
      request_subjects: [],
      display_name: "",
      request_person_name: "",
      request_department: "",
      request_from_date: false,
      request_to_date: false,
      request_other_delimitations: "",
      requester_type: "Borger selv",
      requester_name: "",
      request_admin_unit: "",
      request_received_date: "",
      request_deadline_date: "",
      request_deadline_reason: "",
      request_date_full: true,
      document_types: [],
      law_set: { name: "Forvaltningsloven" },
      reference_number: "",
      comment: "",
      request_process_type: "manual"
    });

    const steps = reactive([
      { number: 1, title: "Anmodning", completed: false },
      { number: 2, title: "Indtast oplysninger", completed: false },
      { number: 3, title: "Opret sag", completed: false },
    ]);

    const stepsMap = ref({
      "step-1" : 1,
      "step-2" : 2,
      "step-3" : 3
    });
    const step = ref("step-1");
    const isEdit = ref(false);
    const showRequestDeadlineReason = ref(false);
    const requesterTypeOptions = ref([
      "Borger selv",
      "Forældremyndighedsindehaver",
      "Bemyndiget tredjepart",
      "Virksomhed",
      "Anden",
    ]);

    const isRPASelected = ref(false);

    // mounted
    onMounted(async () => {
      if (!documentTypes.value.id) {
        store.dispatch("loadDocumentTypes");
      }
      // If store not updatede correct for edit part then call dispatch again
      if (props.processId && props.processId !== openProcess.value.process_id) {
        await store.dispatch("loadSpecificProcess", props.processId);
      }
      if (
        props.processId &&
        openProcess.value &&
        props.processId === openProcess.value.process_id
      ) {
        isEdit.value = true;
        Object.assign(process, openProcess.value);

        // Update last step title to reflect that this is editing a case 
        steps[2].title = "Opdater sag";
      } else {
        // set default law set if new process
        process.law_set = defaultLawSet.value;

        // setting dates
        
        process.request_received_date = dayjs().format("YYYY-MM-DD");
        process.request_deadline_date = dayjs(process.request_received_date).businessDaysAdd(7).format('YYYY-MM-DD');
      }
      // check if request_subjects contains info
      if (
        !process.request_subjects ||
        process.request_subjects.length === 0
      ) {
        process.request_subjects.push({
          name: "",
          cpr: "",
        });
      }
      process.document_types = rpaDocumentTypes.value;
      store.dispatch("getTooltipsList")

      // If not in edit mode
      if(!isEdit.value){
        if(showRPA()) {
            isRPASelected.value = true;
            process.request_process_type = "rpa"
        }
      }
      // Load laws if it has not already been loaded
      if (lawsLoaded.value === false) {
        store.dispatch("loadLaws");
      }
      // if (proxy.$featureflag(FEATURE_FLAGS.enableCPRInput)) {
      //   console.log("Hey1");
      //   enableCPRInput = true;
      //   console.log("Hey2");
      // }
    });

    // vuelidate
    const { forEach } = helpers
    const cprValidator = helpers.withMessage('Indtast venligst et korrekt CPR', helpers.regex(
      /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?[\dA-Za-zÆØÅæøå]{4}$/
    ));
    let requestSubjectsRules;
    let rules;
    store.commit("acadre/setChosenAcadreDocumentsCount", [])
    if (proxy && proxy.$featureflag && proxy.$featureflag(FEATURE_FLAGS.enableCPRInput)) {
      requestSubjectsRules = {
        name: { required },
        cpr: { required, regex: cprValidator }
      }
      rules = reactive({
        request_person_cpr: { required, regex: cprValidator },
        requester_cpr: { required, regex: cprValidator },
        request_subjects: {
          $each: forEach(requestSubjectsRules)
        }
      });
    } else {
      requestSubjectsRules = {
        name: { required },
      }
      rules = reactive({
        request_subjects: {
          $each: forEach(requestSubjectsRules)
        }
      });
    }
    const v$ = useVuelidate(rules, process, { $autoDirty: true });

    // Functions
    const touchField = (subject) => {
      subject.$dirty = true;
    }

    function showRPA() {
      return (user.value.userData.rpa_allowed &&
            process.law_set.display_name === 'Forvaltningsloven')
    }

    const updateProcessType = () => {
      process.request_process_type = isRPASelected.value ? 'rpa' : 'manual';
    };
    return {
      documentTypes,
      openProcess,
      processes,
      user,
      documentationToUpload,
      defaultProcessStatus,
      openDocumentations,
      tooltips,
      lawSets,
      lawsLoaded,
      defaultLawSet,
      acadreDocumentsCount,
      rpaDocumentTypes,
      process,
      stepsMap,
      steps,
      step,
      isEdit,
      showRequestDeadlineReason,
      requesterTypeOptions,
      isRPASelected,
      v$,
      touchField,
      showRPA,
      updateProcessType,
      enableCPRInput,
    };
  },
  data() {
    return {
      //empty array waiting for button NySag to be clicked 
      clicked: [],
      token: localStorage.getItem("aktio-ai-user-token"),
      // enableCPRInput: false,
      // adminUnitOptions: [
      //   "Børn, Uddannelse og Sundhed",
      //   "Job og Velfærd",
      //   "Kultur og Ervherv",
      //   "Teknik og Miljø",
      //   "Økonomi",
      // ],
    };
  },
  computed: {
    step1ready() {
      if (
        this.process.law_set.display_name &&
        this.process.request_received_date &&
        this.process.request_deadline_date &&
        (((this.process.law_set.display_name === "Forvaltningsloven" ||
          this.process.law_set.display_name === "Sundhedsloven") &&
          this.process.requester_type !== "Borger selv" &&
          // !this.v$.requester_cpr.$invalid &&
          this.process.requester_name) ||
          this.process.requester_type !== "Forældremyndighedsindehaver")
      ) {
        return true;
      } else {
        return false;
      }
    },
    step2ready() {
      if (
        ((this.process.law_set.display_name === "Forvaltningsloven" ||
          this.process.law_set.display_name === "Sundhedsloven") &&
          !this.v$.request_subjects.$invalid &&
          this.process.requester_type &&
          (this.process.request_date_full ||
            (this.process.request_from_date &&
              this.process.request_to_date))) ||
        ((this.process.law_set.display_name === "Offentlighedsloven" ||
          this.process.law_set.display_name === "Miljøoplysningsloven" ||
          this.process.law_set.display_name === "Egenacces jf. Offentlighedsloven" ||
          this.process.law_set.display_name === 'Indsigtssag') &&
          this.process.request_subjects[0].name &&
          this.process.request_subjects[0].name.length > 0 &&
          !this.v$.request_subjects.$anyError &&
          (this.process.request_date_full ||
            (this.process.request_from_date && this.process.request_to_date)))
      ) {
        return true;
      } else {
        return false;
      }
    },
    step3ready() {
      if (
          this.process.request_process_type == "manual" ||
          (this.process.request_process_type == "rpa" &&
            this.user.userData.rpa_allowed &&
            this.process.document_types &&
            this.process.document_types.length > 0
          ) || (
            this.isEdit &&
            this.process.display_name.length > 0
          )
      ) {
        return true;
      } else {
        return false;
      }
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
    request_to_date_min() {
      if (this.process.request_from_date) {
        return this.process.request_from_date;
      } else {
        return dayjs().format("YYYY-MM-DD");
      }
    },
  },
  watch: {
    /* "process.acadre_case_id"(val) {
      console.log(val);
      if (val) {
        this.process.request_subjects[0].name ="Acadre Sag #"+ val
        this.process.request_subjects[0].cpr = "1111111111"
      }
    }, */
    // "isActive"() {
    //   if(!this.step3ready || !this.disableSubmit) {
    //     this.isActive = false;
    //     console.log("disablesubmit" + this.disableSubmit);
    //   }
    // },
    "process.request_date_full"(val) {
      if (!val && !this.isEdit) {
        this.process.request_from_date = "";
        this.process.request_to_date = "";
      }
    },
    "process.request_received_date"(newVal, oldVal) {
      if (newVal && newVal !== oldVal && !this.isEdit) {
        this.process.request_deadline_date = dayjs(newVal)
          .businessDaysAdd(7)
          .format("YYYY-MM-DD");
      }
    },
    "process.request_deadline_date"(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.showRequestDeadlineReason = true;
      }
    },
    "defaultLawSet"(lawSet){
      // As the lawSet might be loaded after the component has already been mounted
      // we need to listen for changes in the default lawset and set the law set to it
      this.process.law_set = lawSet;
    }
  },
  created() {
    // close on e
    const onEscape = (e) => {
      if (e.keyCode === 27) {
        this.closeModal();
      }
    };
    document.addEventListener("keydown", onEscape);
    /* this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    }); */
    emitter.on('cleanup', () => {
      document.removeEventListener('keydown', onEscape);
    });
    this.enableCPR()
  },
  methods: {
    closeModal() {
      this.$store.dispatch("posthogCapture", {event_name:"closeEditCaseModal", data: {}})
      this.$store.commit("clearDocumentationToUpload");
      this.$emit("close");
    },
    goToStep1() {
      this.$store.dispatch("posthogCapture", {event_name:"GoToCaseStep1", data: {}})
      this.step = "step-1";
      this.activeStep = 1
      
      this.process.request_process_type = false;
    },
    goToStep2() {
      this.$store.dispatch("posthogCapture", {event_name:"GoToCaseStep2", data:{}});
      if (this.step1ready) {
        this.step = "step-2";
        this.activeStep = 2

        // Update step 1 to be completed
        this.steps[0].completed = true;
      }
      if(!this.showRPA()){
          this.process.request_process_type = "manual";
      } 
    },
    goToStep3() {
      this.$store.dispatch("posthogCapture", {event_name:"GoToCaseStep3", data:{}});
      if (this.step2ready) {
        this.step = "step-3";
        this.activeStep = 3;

        // Update step 2 to be completed
        this.steps[1].completed = true;
      }
       if(!this.showRPA()){
          this.process.request_process_type = "manual";
      }
    },
    updateStep(step){
      switch(step){
        case 1:
          this.goToStep1();
          break;
        case 2:
          this.goToStep2();
          break;
        default:
          this.goToStep3();
      }
    },
    setProcessType(processType) {
      this.step = "step-2";
      this.process.request_process_type = processType;
    },
    async deleteRequestSubject(requestSubjectId, requestSubjectIndex) {
      if (requestSubjectId) {
        await this.$store.dispatch("deleteRequestSubject", requestSubjectId);
      }
      this.process.request_subjects.splice(Number(requestSubjectIndex), 1);
    },
    addAnotherSubject() {
      this.$store.dispatch("posthogCapture", {event_name:"AddAnotherSubject", data:{}});

      this.process.request_subjects.push({
        name: "",
        cpr: "",
      });
    },
    calculateDeadline(startDate, daysToAdd) {
      return dayjs(startDate).businessDaysAdd(daysToAdd).format('YYYY-MM-DD');
    },
    updateEndDate() {
      if (this.process.law_set.display_name === 'Forvaltningsloven') {
        this.process.request_deadline_date = this.calculateDeadline(this.process.request_received_date, 7)
      } else if (this.process.law_set.display_name === 'Miljøoplysningsloven') {
        this.process.request_deadline_date = this.calculateDeadline(this.process.request_received_date, 30)
      } else if (this.process.law_set.display_name === "Offentlighedsloven") {
        this.process.request_deadline_date = this.calculateDeadline(this.process.request_received_date, 7)
      } else if (this.process.law_set.display_name === "Sundhedsloven") {
        this.process.request_deadline_date = this.calculateDeadline(this.process.request_received_date, 7)
      } else if (this.process.law_set.display_name === "Egenacces jf. Offentlighedsloven") {
        this.process.request_deadline_date = this.calculateDeadline(this.process.request_received_date, 7)
      } else if (this.process.law_set.display_name === "Indsigtssag") {
        this.process.request_deadline_date = this.calculateDeadline(this.process.request_received_date, 30)
      }
    },
    async createCaseHelper(processWithUserData) {
      const processId = uuidv4();

      // Send RPA request if allowed and not in edit mode
      if (this.process.request_process_type === "rpa" && 
          this.user.userData.rpa_allowed &&
          !this.isEdit
      ) {
        let fromDate = this.process.request_from_date;
        if (this.process.request_date_full === true) {
          fromDate = "1970-01-01";
        }

        const emailRequest = {
          process_id: processId,
          request_from_date: fromDate,
          request_user_data: this.user.userData,
          request_cpr: processWithUserData.request_subjects[0].cpr.replace(
            /[^0-9]/g,
            ""
          ),
          request_doc_types: _.map(this.process.document_types, "name"),
        };
        await this.$store.dispatch("pushRPAEmail", emailRequest);
        const toast_message = '<strong>Der er oprettet en RPA kørsel på din sag, som starter i dag kl. 15</strong><br/><br/>I sagens dokumentoversigt kan du se status på robotten og få hjælp til processen.';
        this.$showRegisterToast(toast_message, "info", 15000, {})
      }

      // Create title suggestion based on the requester and request subject info
      // if it's creation mode
      if(!this.isEdit) {
        const {
          request_subjects,
          requester_name
        } = processWithUserData;
        switch(processWithUserData.law_set.display_name) {
          case "Forvaltningsloven":
            if(this.process.requester_type === "Borger selv" || this.process.requester_type === "Virksomhed") {
              processWithUserData.display_name = request_subjects[0].name;
            } else {
              processWithUserData.display_name = `${request_subjects[0].name} anmodet af ${requester_name}`;
            }
            break;
          case "Sundhedsloven":
            if(this.process.requester_type === "Borger selv" || this.process.requester_type === "Virksomhed") {
              processWithUserData.display_name = request_subjects[0].name;
            } else {
              processWithUserData.display_name = `${request_subjects[0].name} anmodet af ${requester_name}`;
            }
            break;
          case "Offentlighedsloven":
            if(requester_name === "") {
              processWithUserData.display_name = `${request_subjects[0].name}`;
            } else {
              processWithUserData.display_name = `${request_subjects[0].name} anmodet af ${requester_name}`;
            }
            break;
          case "Egenacces jf. Offentlighedsloven":
            if(requester_name === "") {
              processWithUserData.display_name = `${request_subjects[0].name}`;
            } else {
              processWithUserData.display_name = `${request_subjects[0].name} anmodet af ${requester_name}`;
            }
            break;
          case "Indsigtssag":
            if(requester_name === "") {
              processWithUserData.display_name = `${request_subjects[0].name}`;
            } else {
              processWithUserData.display_name = `${request_subjects[0].name} anmodet af ${requester_name}`;
            }
            break;
          case "Miljøoplysningsloven":
            if(requester_name === "") {
              processWithUserData.display_name = `${request_subjects[0].name}`;
            } else {
              processWithUserData.display_name = `${request_subjects[0].name} anmodet af ${requester_name}`;
            }
            break;
        }
      }
      let response = {};
      if (this.isEdit) {
        // when editing remove linked data
        delete processWithUserData["redacted_process"];
        delete processWithUserData["decision_letter"];
        delete processWithUserData["documents"];
        delete processWithUserData["documents_to_converts"];
        delete processWithUserData["process_status"];
        delete processWithUserData["redacted_process_status"];
        delete processWithUserData["process_decision"];

        response = await this.$store.dispatch(
          "updateProcess",
          processWithUserData
        );
        // Update RestorationStatus
        await this.$store.dispatch("updateRestorationStatus", {
          processId: this.processId,
          status: "ChangesDetected"
        });
        // console.log(response)
      } else {
        // Overwrite process id to be unique for new processes
        processWithUserData["process_id"] = processId;
        response = await this.$store.dispatch(
          "pushProcess",
          processWithUserData
        );
      }
      if (!this.isEdit) {
        if(response.id) {
          // this.$store.dispatch(
          //   "createCaseWithAcadre",
          //   { "acadre_case_id":processWithUserData.acadre_case_id, "processId":processId, "internalProcessId":response.id, "annotationCategory":this.annotationCategory }
          // )
          this.$store.dispatch(
            "acadre/getAllSelectedDocumentsFromAcadre",
            { "processId":processId, "internalProcessId":response.id }
          )
        }
      }
      if (response.id !== undefined) {
        const uploadId = uuidv4();
        for (
          let index = 0;
          index < this.documentationToUpload.length;
          index++
        ) {
          let doc = this.documentationToUpload[index];

          let documentTypeId = 0;
          if (doc.docInfo.type === "original_request") {
            const foundDocType =
              this.documentTypes.find((d) => d.name === "Anmodning") || {};
            documentTypeId = foundDocType.id || 0;
          } else if (doc.docInfo.type === "power_of_attorney") {
            const foundDocType =
              this.documentTypes.find((d) => d.name === "Fuldmagt") || {};
            documentTypeId = foundDocType.id || 0;
          }
          await this.$store.dispatch("processFiles", {
            file: doc.file,
            // original_file: doc.file,
            extension: doc.docInfo.extension,
            sort_order: index,
            upload: true,
            processId: response.process_id,
            internalProcessId: response.id,
            uploadId: uploadId,
            document_type: {
              id: documentTypeId,
            },
          });
        }
        this.closeModal();
        return response.process_id;
      }
    },
    //TODO: clean up all these if statements and find a better solution
    async createCase() {
      //push to array Clicked when button NySag is clicked to disable it
      this.clicked.push('nySagClicked')
      if(this.step3ready) {
        this.$store.dispatch("posthogCapture", {event_name:"FinishCase", data: {}});
      
        // set logged in user
        let processWithUserData = this.process;
        if (!this.process.users) {
          processWithUserData["users"] = this.user.userData.id;
        }
        if (this.process.requester_cpr) {
          processWithUserData[
            "requester_cpr"
          ] = this.process.requester_cpr.replace(/[^0-9]/g, "");
        }

        // set default process status
        processWithUserData.process_status = this.defaultProcessStatus || {
          id: 0,
        };

        // remove empty dates so Strapi doesn't complain
        if (!this.process.request_from_date) {
          delete processWithUserData["request_from_date"];
        }
        if (!this.process.request_to_date) {
          delete processWithUserData["request_to_date"];
        }

        // When there is no date limit, set to/from date to null
        if(this.process.request_date_full) {
          processWithUserData["request_from_date"] = null;
          processWithUserData["request_to_date"] = null;
        }

        // handle subject requests
        const requestSubjects = this.process.request_subjects || [];
        let requestSubjectsResponse = undefined;
        if (requestSubjects.length > 0) {
          for (let index = 0; index < requestSubjects.length; index++) {
            let requestSubject = requestSubjects[index];

            // remove dash from cpr
            if (requestSubject.cpr) {
              requestSubject.cpr = requestSubject.cpr.replace(/[^0-9]/g, "");
            }

            // if no id,  create subject
            if (!requestSubject.id) {
              const newRequestSubject = await this.$store.dispatch(
                "pushRequestSubject",
                requestSubject
              );
              requestSubjectsResponse = await newRequestSubject;
            }
            // update if id is present
            else {
              const updatedRequestSubject = await this.$store.dispatch(
                "updateRequestSubject",
                requestSubject
              );
              requestSubjectsResponse = await updatedRequestSubject;
            }
            processWithUserData["request_subjects"] = [requestSubjectsResponse];
            const ret_val = await this.createCaseHelper(processWithUserData)
            // Reload open process to reflect changes
            this.$store.commit("setFiles", [])
            await this.$store.dispatch("loadSpecificProcess", ret_val);
            await this.$router.push({name: "Processes", params: {processId: ret_val}});
          }
        } else {
          const ret_val = await this.createCaseHelper(processWithUserData)
          // Reload open process to reflect changes
          this.$store.commit("setFiles", [])
          await this.$store.dispatch("loadSpecificProcess", ret_val);
          
          await this.$router.push({name: "Processes", params: {processId: ret_val}});
        }
      }
      this.closeModal();
    },
    disableRPA() {
      return !this.$featureflag(FEATURE_FLAGS.disableIntegrationRPA)
    },
    enableCPR() {
      if(this.$featureflag(FEATURE_FLAGS.enableCPRInput)){
        this.enableCPRInput = true;
      }
    },
    toastIfDisabled() {
      if (this.user.userData.rpa_allowed === false) {
        const toast_message = '<strong>Hvis du mangler adgang til denne funktion (robotten) kontakt da support. Se mere under "Hjælp".</strong>'
        this.$showRegisterToast(toast_message, "info", 4000, {})
      }
    },
    openImportExtDocModal() {
      this.$store.dispatch("posthogCapture", {event_name:"ImportExternalDocuments", data:{}});

      this.$modal.show(
        ImportExternalDocumentsForm,
        {},
        {
          classes: 'overflow-visible',
          height: "95%",
          width: "75%",
          shiftY: 0.15,
          scrollable: true,
          clickToClose: false,
          draggable: false,
        },
        {
          closed: this.clearDocumentation,
        }
      );
    },
  },
};
</script>

<style>
  .step3 {
    width: 600px;
  }

 .form-checkbox {
    appearance: none;
    background-color: #fff;
    border: 1px solid #d1d5db;
    padding: 0.5rem;
    display: inline-block;
    position: relative;
    border-radius: 0.25rem;
    margin-top: 1px;
  }

  .form-checkbox:checked {
    background-color: #6b46c1;
    border-color: #6b46c1;
    color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='white'%3e%3cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 00-1.414-1.414L7 12.586 4.707 10.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l8-8z' clip-rule='evenodd'/%3e%3c/svg%3e");
    background-size: 1rem 1rem;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 1px;
  }
</style>