<template>
  <div id="login">
    <!-- <NavbarNew /> -->
    <div class="min-h-screen flex justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <!-- strapi login form -->
      <div class="max-w-md w-full">
        <div>
          <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Velkommen
          </h2>
        </div>

        <h3
          v-if="loginError"
          class="mt-4 text-center text-red-500"
        >
          Forkert e-mail eller password
        </h3>
        <div class="mt-4">
          <input
            type="hidden"
            name="remember"
            value="true"
          >
          <div class="shadow-sm">
            <div>
              <input
                v-model="user.identifier"
                aria-label="E-mail adresse"
                name="email"
                type="email"
                required
                class="appearance-none relative block w-full px-3 py-2 mb-4 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="E-mail adresse"
                @keydown="removeError()"
              >
            </div>
            <div class="-mt-px">
              <input
                v-model="user.password"
                aria-label="Password"
                name="password"
                type="password"
                required
                class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Password"
                @keydown="removeError()"
                @keyup.enter="loginUser()"
              >
            </div>
          </div>

          <!--          <div class="mt-6 flex justify-end">-->
          <!--            <div class="text-sm leading-5">-->
          <!--              <a-->
          <!--                href="mailto:kontakt@aktio.ai"-->
          <!--                class="font-medium text-blue-600 hover:text-blue-800 focus:outline-none focus:underline transition ease-in-out duration-150"-->
          <!--              >-->
          <!--                Har du glemt dit password?-->
          <!--              </a>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="mt-6">
            <standard-button 
              class="w-full"
              button-type="standard-disableable"
              :button-disabled="!user.identifier || !user.password" 
              button-text="Log ind" 
              button-icon="lock"
              @click="loginUser()"
            />
          </div>
        </div>
        <!--        <div-->
        <!--          class="mt-6 flex justify-end"-->
        <!--          v-if="locationHostname === `${process.env.VUE_APP_SERVER_URL}`"-->
        <!--        >-->
        <!--          <div class="text-sm leading-5">-->
        <!--            <router-link-->
        <!--              to="/register"-->
        <!--              class="font-medium text-aktio-darkblue-900 hover:text-aktio-darkblue-900 focus:outline-none focus:underline transition ease-in-out duration-150"-->
        <!--            >-->
        <!--              Opret konto-->
        <!--            </router-link>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { mapState } from "vuex";
import posthog from "posthog-js";
import AppFooter from "@/components/Footer.vue";
import FEATURE_FLAGS from "@/constants/featureflags";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "LoginStrapi",
  components: {
    AppFooter,
    StandardButton,
  },
  data() {
    return {
      user: {
        identifier: "",
        password: "",
      },
      loginError: false,
      locationHostname: location.hostname,
    };
  },
  computed: {
    ...mapState("user", {
      isAuthenticated: (state) => state.isAuthenticated,
    }),
  },
  methods: {
    async loginUser() {
      let response;
      let data;

      try {
        //console.log("strapiURL:" + this.$store.state.strapiUrl);
        response = await fetch(this.$store.state.strapiUrl + "/auth/local", {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "omit", // include, *same-origin, omit
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(this.user), // body data type must match "Content-Type" header
        });
      } catch (error) {
        localStorage.removeItem("aktio-ai-user-token");
      }
      data = await response.json();

      if (data.jwt != undefined) {
        try {
          const userData = data.user;
          // Identify user
          if (data.user.username) {
            try {
              // console.log('Vue.app - Identifying Posthog user: ' + userData.username.toString())
              posthog.identify(userData.username.toString());
              // console.log('Vue.app - Set email Posthog user: ' + userData.email.toString())
              posthog.people.set({ email: userData.email.toString() })
            } catch (error) {
              console.log(error);
            }

            try {
              // Reload feature flags
              posthog.reloadFeatureFlags();
              posthog.onFeatureFlags(() => {
                if (this.$featureflag(FEATURE_FLAGS.enableSessionRecording)) {
                  console.log("Session recording is enabled - your screen is being captured.");
                  posthog.startSessionRecording();
                }

                if (this.$featureflag(FEATURE_FLAGS.testFeatureFlag)) {
                  console.log("This console log is printed, because the 'test' feature flag is enabled! This feature flag is used to test if feature flags are working.")
                }
              })
            } catch (error) {
              console.log(error)
            }
          }
          this.$store.dispatch("posthogCapture", { event_name: "LogMeIn", data: {} });
        } catch (error) {
          console.log(error);
        }

        this.$store.commit("user/setUser", data.user)
        this.$store.commit("user/authenticate", true);

        localStorage.setItem("aktio-ai-user-token", data.jwt);
        localStorage.setItem("loginMethod", "regular");

        // If this is the first time user has logged in, 
        // send to my profile to fill out their data
        if (data.user.first_login) {
          this.$router.push("/profile");
        } else {
          const redirectPath = this.$route.query.redirect;
          if (redirectPath && !redirectPath.includes("login")) {
            this.$router.push(redirectPath);
          } else {
            this.$router.push('/processes');
          }
        }
      } else {
        localStorage.removeItem("aktio-ai-user-token");
        this.loginError = true;
        this.$store.commit("user/authenticate", false);
      }
    },
    removeError() {
      this.loginError = false;
    },
  },
};
</script>