import { createApp } from 'vue'
// import { createPinia } from 'pinia'

import '@/assets/css/tailwind.css'
import '@/assets/css/htmlEditor.css'
import App from './App.vue'
import VueKonva from 'vue-konva'
import VueScrollTo from 'vue-scrollto'
import VueToast from 'vue-toast-notification'
import store from './store'
import router from './router.js'
import vClickOutside from 'v-click-outside'
import VueObserveVisibility from 'vue-observe-visibility'
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import FeatureFlag from './plugins/featureflag.js'
import modalPlugin from './plugins/modalPlugin';
// import VueTailwind from 'vue-tailwind'
// import VueTailwindSettings from './constants/componentsSettings'

/* Fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* Fontawesome icons */
import { 
  faTrashCan,
  faCheck,
  faMagnifyingGlass,
  faRotateRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faAngleDown,
  faAngleUp,
  faUpload,
  faK,
  faL,
  faM,
  faN,
  faO,
  faS,
  faT,
  faSpinner,
  faFileLines as faFileLinesSolid, // Aktio Indsigt
  faFileExport,
  faMagnifyingGlassChart, // SCANNER
  faScissors, // KLIP
  faLaptopCode, // GPT?
  faLaptopFile, // OCR
  faFileSignature, // EDITOR
  faHourglassStart, // TIDSMASKINE
  faBook,
} from '@fortawesome/free-solid-svg-icons'

import {
  faFileLines as faFileLinesRegular // Aktio Indsigt Light
} from '@fortawesome/free-regular-svg-icons'

import 'vue-toast-notification/dist/theme-sugar.css'
import 'flowbite';
import ShowRegisterToast from "./plugins/showRegisterToast";

const scrollToOptions = {
  container: "body",
  duration: 150,
  easing: "ease",
  // offset: -80,
  force: false,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
}

/* add icons to the library */
library.add(faTrashCan)
library.add(faCheck)
library.add(faMagnifyingGlass)
library.add(faRotateRight)
library.add(faArrowLeft)
library.add(faArrowUp)
library.add(faArrowDown)
library.add(faAngleDown)
library.add(faAngleUp)
library.add(faUpload)
library.add(faK)
library.add(faL)
library.add(faM)
library.add(faN)
library.add(faO)
library.add(faS)
library.add(faT)
library.add(faSpinner)
library.add(faMagnifyingGlassChart)
library.add(faScissors)
library.add(faLaptopCode)
library.add(faLaptopFile)
library.add(faHourglassStart)
library.add(faFileExport)
library.add(faFileSignature)
library.add(faFileLinesSolid)
library.add(faFileLinesRegular)
library.add(faBook)

const app = createApp(App)
// app.use(createPinia())

/* add font awesome icon component */
app.component('FontAwesomeIcon', FontAwesomeIcon)

app.use(VueKonva)
app.use(VueToast, {position: 'top'})
app.use(FeatureFlag)
app.use(VueScrollTo, scrollToOptions)
app.use(vClickOutside)
// app.config.productionTip = false
app.use(VueObserveVisibility)
app.use(VueCustomTooltip, {
  multiline: true,
  size: "is-medium"
})
// app.use(VueTailwind, VueTailwindSettings)

app.config.errorHandler = (err, vm, info) => {
  console.error('Global Error Handler:', err, info);
};

app.use(router)
  .use(store)
  .use(ShowRegisterToast, store)
  .use(modalPlugin, store)
  .mount('#app')

/* new Vue({
  render: h => h(App),
  router,
  store,
  created () {
    Vue.use(ShowRegisterToast, store)
  }
}).$mount('#app') */