import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/pages/Home.vue'
import Processes from '@/pages/Processes.vue'
import Settings from '@/pages/Settings.vue'
import Login from '@/pages/Login.vue'
import LoginStrapi from '@/pages/LoginStrapi.vue'
import AuthRedirect from '@/pages/AuthRedirect.vue'
import DocumentViewer from '@/pages/DocumentViewer.vue'
import TextAnnotateViewer from '@/pages/TextAnnotateViewer.vue'
import Profile from '@/pages/Profile.vue'
import Help from '@/pages/Help.vue'
import AktioServices from "@/pages/AktioServices.vue"
// import KombitCallback from '@/pages/KombitCallback'
import SSOLogin from '@/pages/SSOLogin.vue'
import store from "@/store/index.js"
import Survey from '@/pages/Survey.vue'

const routes = [
  { path: '/login', name: 'LoginKombit', component: Login },
  { path: '/login-strapi', name: 'Login', component: LoginStrapi },
  { path: '/sso', name: "SSO", component: SSOLogin },
  // { path: '/sso', name: "KombitCallback", component: KombitCallback },
  { path: '/aktio', name: "AktioServices", component: AktioServices, meta: { requiresAuth: true } },
  { path: '/authRedirect', name: 'AuthRedirect', component: AuthRedirect },
  { path: '/survey/:surveyGuid?', name: 'Survey', component: Survey, meta: { requiresAuth: true } },
  { path: '/profile', name: 'Min profil', component: Profile, meta: { requiresAuth: true }},
  { path: '/help', name: 'Help', component: Help, meta: { requiresAuth: true }},
  { path: '/', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
  { path: '/processes/:processId?', name: "Processes", component: Processes, meta: { requiresAuth: true } },
  { path: '/processes/:processId/:decision', name: "Decision", component: Processes, meta: { requiresAuth: true } },
  { path: '/settings', name: "Settings", component: Settings, meta: { requiresAuth: true } },
  {
    path: '/viewer/process/:processId?/documents/:documentId?/:pageNumber?/:redactionId?/:randomNum?/newViewer',
    name: "DocumentViewer",
    component: DocumentViewer,
    meta: { requiresAuth: true }
  },
  {
    path: '/viewer/process/:processId?/documents/:documentId?/:pageNumber?/textViewer',
    name: "TextAnnotateViewer",
    component: TextAnnotateViewer,
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  // mode: 'history',
  history: createWebHistory(),
  routes
})
function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

router.beforeEach((to, from, next) => {
  const jwt_token = localStorage.getItem('aktio-ai-user-token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth) {
    if (jwt_token) {
      try {
        const jwtPayload = parseJwt(jwt_token);
        if (jwtPayload.exp < Date.now() / 1000) {
          handleTokenExpired(next);
        } else {
          handleTokenValid(next);
        }
      } catch (error) {
        console.error('JWT parsing error:', error);
        handleTokenExpired(next);
      }
    } else {
      handleTokenExpired(next);
    }
  } else {
    next();
  }
});

function handleTokenExpired(next) {
  localStorage.removeItem("aktio-ai-user-token");
  store.commit("user/authenticate", false);
  store.commit("authenticate", false);
  store.commit("user/resetState");
  store.commit("notification/resetState")
  store.commit("resetState");
  const currentPath = window.location.pathname;
  const redirectUrl = `/login?redirect=${encodeURIComponent(currentPath)}`;
  next(redirectUrl);
}

function handleTokenValid(next) {
  store.commit("user/authenticate", true);
  store.commit("authenticate", true);
  next();
}

router.onError((error) => {
  console.error('Routing error:', error);
});

export default router